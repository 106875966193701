import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';

import { DestroyableComponent } from '../../shared/utils/destroyable-component';
import { interval } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'agrigo-product-view',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './ui.html',
  styleUrls: ['./style.scss'],
})
export class ProductViewComponent extends DestroyableComponent implements OnInit {

  sensor = {
    temp: '21°C'
  };
  showAdvanced = false;

  constructor(private cdr: ChangeDetectorRef) {
    super();
  }

  ngOnInit() {
    interval(5000).pipe(
      takeUntil(this.onDestroy$)
    ).subscribe(() => {
      this.sensor.temp = this.sensor.temp === '21°C' ? '22°C' : '21°C';
      this.cdr.detectChanges();
    });
  }
}
