import { ChangeDetectionStrategy, Component, HostBinding, HostListener, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

import { DestroyableComponent } from '../../utils/destroyable-component';
import { LINKS } from '../../config/links';
import { LanguageService } from '../../../localization/service';
import { ILanguage } from '../../../localization';

@Component({
  selector: 'agrigo-header',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './ui.html',
  styleUrls: ['./style.scss'],
})
export class HeaderComponent extends DestroyableComponent implements OnInit {

  menuState: boolean;
  languages: ILanguage[];
  links = LINKS;
  selectedLanguage$: Observable<ILanguage>;

  @HostBinding('class.scrolled')
  scrolled: boolean;

  constructor(private translate: LanguageService) {
    super();
  }

  ngOnInit() {
    this.menuState = false;
    this.languages = this.translate.getSupportedLanguages();
    this.selectedLanguage$ = this.translate.getSelectedLanguage();

    this.updateStyle();
  }

  @HostListener('window:scroll')
  handleScroll() {
    this.updateStyle();
    if (this.menuState) {
      this.menuState = false;
    }
  }

  changeLanguage(langCode: string) {
    this.translate.setLanguage(langCode);
  }

  updateStyle () {
    this.scrolled = window.scrollY > 0;
  }

  scrollTo(el: HTMLElement) {
    el.scrollIntoView();
  }
}
