import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AboutViewComponent } from './component';
import { HeaderModule } from '../../shared/components/header';
import { FooterModule } from '../../shared/components/footer';
import { LocalizationModule } from '../../localization';

@NgModule({
    imports: [
        CommonModule,
        HeaderModule,
        FooterModule,
        LocalizationModule
    ],
  declarations: [AboutViewComponent],
  exports: [AboutViewComponent]
})
export class AboutViewModule {}
