import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ContactSectionComponent } from './component';
import { LocalizationModule } from '../../../localization';

@NgModule({
  imports: [
    CommonModule,
    LocalizationModule
  ],
  declarations: [ContactSectionComponent],
  exports: [ContactSectionComponent]
})
export class ContactSectionModule {}
