import { Inject, Injectable } from '@angular/core';
import { DefaultLangChangeEvent, LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ILanguage, ILocalizationConfig } from './interfaces';

import { Languages } from './languages';

@Injectable()
export class LanguageService {
  private supportedLanguages: any[];

  get currentLang(): string {
    return this.translate.currentLang;
  }

  constructor(private translate: TranslateService, @Inject('LOCALIZATION_CONFIG') private config: ILocalizationConfig) {
    this.init();
  }

  setLanguage(languageCode: string) {
    console.log('setLanguage', languageCode, this.translate);
    this.translate.use(languageCode);
    localStorage.setItem('actualLanguage', languageCode);
  }

  onLanguageChange(): Observable<string> {
    return combineLatest(
      this.translate.onDefaultLangChange,
      this.translate.onLangChange,
      (def: DefaultLangChangeEvent, curr: LangChangeEvent) => curr.lang || def.lang
    );
  }

  getSelectedLanguage(): Observable<ILanguage> {
    return this.onLanguageChange().pipe(
      map(code => this.getSupportedLanguages().find(lang => lang.id === code))
    );
  }

  getAllLanguages(): ILanguage[] {
    return [...Languages];
  }

  getLanguageDescOfId(languageId: string): ILanguage | undefined {
    return Languages.find(lang => lang.id === languageId);
  }

  getSupportedLanguages(): ILanguage[] {
    return this.supportedLanguages;
  }

  isSupportedLanguage(langCode: string): boolean {
    return this.config.supportedLanguages.indexOf(langCode) > -1;
  }

  init() {
    this.translate.addLangs(this.config.supportedLanguages);
    this.translate.setDefaultLang(this.config.defaultLanguage);
    let actualLanguage: string | null = localStorage.getItem('actualLanguage');

    if (!actualLanguage || !this.isSupportedLanguage(actualLanguage)) {
      const browserLang = this.translate.getBrowserCultureLang() || 'en-US';
      actualLanguage =
        this.isSupportedLanguage(browserLang) ? browserLang : this.config.defaultLanguage;
    }

    this.setLanguage(actualLanguage);
    this.supportedLanguages = Languages.filter(lang => this.config.supportedLanguages.indexOf(lang.id) > -1);
  }
}
