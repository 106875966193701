import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UsecaseViewComponent } from './component';
import { HeaderModule } from '../../shared/components/header';
import { FooterModule } from '../../shared/components/footer';
import { ContactSectionModule } from '../../shared/components/contact-section';
import { LocalizationModule } from '../../localization';
import { RouterModule } from '@angular/router'

@NgModule({
    imports: [
        CommonModule,
        HeaderModule,
        FooterModule,
        LocalizationModule,
        RouterModule,
        ContactSectionModule
    ],
  declarations: [UsecaseViewComponent],
  exports: [UsecaseViewComponent]
})
export class UsecaseViewModule {}
