import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from "@angular/router";

import { DestroyableComponent } from '../../shared/utils/destroyable-component';

@Component({
  selector: 'agrigo-home-view',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './ui.html',
  styleUrls: ['./style.scss'],
})
export class HomeViewComponent extends DestroyableComponent {

  showVideo: boolean;
  stateClass: string;

  useCases = [ 'poultry', 'greenhouse', 'pig', 'honey', 'fish', 'cow' ];
  features = [ 'comfort', 'safety', 'return' ];

  constructor(private router: Router) {
    super();
  }

  toggleVideo() {
    this.showVideo = !this.showVideo;
  }

  useCaseClick(useCase: string) {
    if(useCase !== 'poultry' && useCase !== 'cow') return;
    this.router.navigate([`/use-case/${useCase}`]);
  }
}
