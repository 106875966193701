import { ChangeDetectionStrategy, Component } from '@angular/core';

import { DestroyableComponent } from '../../shared/utils/destroyable-component';

@Component({
  selector: 'agrigo-about-view',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './ui.html',
  styleUrls: ['./style.scss'],
})
export class AboutViewComponent extends DestroyableComponent {

  sensor = {
    temp: 24
  };
  showAdvanced = false;
}
