import { ILanguage } from '../interfaces';

const languages: ILanguage[] = [
  {
    id: 'en-US',
    short: 'en',
    title: 'lang.english',
    name: 'English (US)',
    // flagImg: require('./flags/us.png'),
    flagTitle: 'country.us'
  },
  {
    id: 'hu-HU',
    short: 'hu',
    title: 'lang.hungarian',
    name: 'Magyar',
    // flagImg: require('./flags/hu.png'),
    flagTitle: 'country.hungary'
  }
];

export { languages as Languages };
