import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HomeViewComponent } from './component';
import { HeaderModule } from '../../shared/components/header';
import { FooterModule } from '../../shared/components/footer';
import { LocalizationModule } from '../../localization';
import { ContactSectionModule } from '../../shared/components/contact-section';
import { RouterModule } from '@angular/router'

@NgModule({
  imports: [
    CommonModule,
    HeaderModule,
    FooterModule,
    ContactSectionModule,
    LocalizationModule,
    RouterModule
  ],
  declarations: [HomeViewComponent],
  exports: [HomeViewComponent]
})
export class HomeViewModule {}
