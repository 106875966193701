import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { NotFoundPageComponent } from './not-found-page.component';
import { HomeViewComponent, HomeViewModule } from './home';
import { ProductViewComponent, ProductViewModule } from './product';
import { AboutViewComponent, AboutViewModule } from './about';
import { UsecaseViewComponent, UsecaseViewModule } from "./use-case";

export const routes: Routes = [
  { path: 'about', component: AboutViewComponent },
  { path: 'home', component: HomeViewComponent },
  { path: 'product', component: ProductViewComponent },
  { path: 'use-case/:use', component: UsecaseViewComponent },
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: '**', component: NotFoundPageComponent },
];

@NgModule({
  declarations: [NotFoundPageComponent],
  imports: [
    RouterModule.forRoot(routes, { useHash: false, scrollPositionRestoration: 'enabled' }),
    AboutViewModule,
    HomeViewModule,
    ProductViewModule,
    UsecaseViewModule
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
