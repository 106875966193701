import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'serket-not-found-page',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div>
      <div>404: Not Found</div>
      <div>
        <p>Hey! It looks like this page doesn't exist yet.</p>
      </div>
      <div>
        <button mat-raised-button color="primary" routerLink="/">Take Me Home</button>
      </div>
    </div>
  `,
  styles: [
    `
      :host {
        text-align: center;
      }
    `,
  ],
})
export class NotFoundPageComponent {}
