import { ChangeDetectionStrategy, Component } from '@angular/core';

import { DestroyableComponent } from '../../utils/destroyable-component';

@Component({
  selector: 'agrigo-contact-section',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './ui.html',
  styleUrls: ['./style.scss'],
})
export class ContactSectionComponent extends DestroyableComponent {

}
