import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router'

import { DestroyableComponent } from '../../shared/utils/destroyable-component';

@Component({
  selector: 'agrigo-usecase-view',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './ui.html',
  styleUrls: ['./style.scss'],
})
export class UsecaseViewComponent extends DestroyableComponent {
  useCase: string;

  constructor(private route: ActivatedRoute, private router: Router) {
    super();
    this.useCase = route.snapshot.paramMap.get('use');
    if(this.useCase !== 'cow' && this.useCase !== 'poultry') router.navigate(['/home'])
  }
}
