import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ProductViewComponent } from './component';
import { HeaderModule } from '../../shared/components/header';
import { FooterModule } from '../../shared/components/footer';
import { LocalizationModule } from '../../localization';

@NgModule({
  imports: [
    CommonModule,
    HeaderModule,
    FooterModule,
    LocalizationModule
  ],
  declarations: [ProductViewComponent],
  exports: [ProductViewComponent]
})
export class ProductViewModule {}
