import { ChangeDetectionStrategy, Component } from '@angular/core';

import { DestroyableComponent } from '../../utils/destroyable-component';
import { LINKS } from '../../config/links';

@Component({
  selector: 'agrigo-footer',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './ui.html',
  styleUrls: ['./style.scss'],
})
export class FooterComponent extends DestroyableComponent {

  menuState: string;
  links = LINKS;

  isSelectedLanguage(langCode: string): boolean {
    return false;
  }
}
