import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HeaderComponent } from './component';
import { LocalizationModule } from '../../../localization';

@NgModule({
  imports: [
    CommonModule,
    LocalizationModule
  ],
  declarations: [HeaderComponent],
  exports: [HeaderComponent]
})
export class HeaderModule {}
