import { CommonModule } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ModuleWithProviders, NgModule } from '@angular/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { LangChangeEvent, TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';

import { localizationConfig } from './config';
import { LanguageService } from './service';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, '/assets/i18n/', '.json');
}

export function _appInitializer() {
  return () => {
    // log.data('[LocalizeModule:app init] initializing localization module...');
  };
}

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [LanguageService],
  exports: [TranslateModule]
})
export class LocalizationModule {
  constructor(private translateService: TranslateService, private language: LanguageService) {
    // this.language.init();
  }

  static forRoot(): ModuleWithProviders {
    return {
      ngModule: LocalizationModule,
      providers: [
        { provide: 'LOCALIZATION_CONFIG', useValue: localizationConfig },
        { provide: APP_INITIALIZER, useFactory: _appInitializer, deps: [LanguageService], multi: true }
      ]
    };
  }
}
