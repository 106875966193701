import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FooterComponent } from './component';
import { LocalizationModule } from '../../../localization';

@NgModule({
    imports: [
        CommonModule,
        LocalizationModule
    ],
  declarations: [FooterComponent],
  exports: [FooterComponent]
})
export class FooterModule {}
