import { Subject } from 'rxjs';
import { OnDestroy } from '@angular/core';

export abstract class DestroyableComponent implements OnDestroy {
  onDestroy$ = new Subject<boolean>();

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }
}
